import { IconButton, Grid, TextField, Autocomplete, InputAdornment, useTheme } from '@mui/material';
import { AddDealInput, UpdateDealPortInput } from 'api';
import { BusinessUnitSelector } from 'components/BusinessUnitSelector';
import { CounterPartySelector } from 'components/CounterPartySelector';
import { IncoTermSelector } from 'components/IncoTermSelector';
import { NumberTextField } from 'components/NumberTextField';
import { ProductSelector } from 'components/ProductSelector';
import { TraderSelector } from 'components/TraderSelector';
import { UserSelector } from 'components/UserSelector';
import { Controller, useWatch, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import capitalize, { capitalizeFirstLetterEveryword } from 'shared/stringFunctions';
import {
  TXT_RECEIVER,
  TXT_LOADING_PORT,
  TXT_UNLOADING_PORT,
  TXT_QUANTITY,
  TXT_TRAFFIC_ANALYST,
  TXT_DEAL_NUMBER,
  TXT_INTERNAL_DEAL,
  TXT_PARENT_NUMBER
} from '../../../../../shared/translations';
import DeleteIcon from '@mui/icons-material/Delete';
import { counterPartyModel, productModel } from 'model';
import _ from 'lodash';
import { useContext } from 'react';
import { DealWizardContext } from './DealWizard';
import { useRecoilState } from 'recoil';
import { tenantState } from 'state/tenantState';

interface DealWizardSplitQuantityProps {
  index: number;
  handleRemove: (index: number) => void;
}

export const DealWizardSplitQuantity = (props: DealWizardSplitQuantityProps) => {
  const {
    control,
    setValue,
    formState: { errors: formErrors },
    register
  } = useFormContext<AddDealInput>();
  const { isDisabled } = useContext(DealWizardContext);
  const { t } = useTranslation();
  const theme = useTheme();
  const [tenant] = useRecoilState(tenantState);

  const watchUnloadingPorts = useWatch({
    control,
    name: 'unloadingPorts'
  });

  const watchLoadingPorts = useWatch({
    control,
    name: 'loadingPorts'
  });

  const watchSplitQuantity = useWatch({
    control,
    name: `splitQuantities.${props.index}`
  });

  const watchMassUnit = useWatch({
    control,
    name: 'massUnit'
  });

  const watchVessel = useWatch({
    control,
    name: 'vessel'
  });

  const getSelectedPort = (isLoading: boolean) => {
    const portOptions = isLoading ? watchLoadingPorts : watchUnloadingPorts;
    if (_.isEmpty(portOptions)) {
      return null;
    }

    const selectedId = isLoading ? watchSplitQuantity.loadingPortId : watchSplitQuantity.unloadingPortId;
    if (!selectedId) {
      return null;
    }

    return (portOptions || []).find((port) => port.id === selectedId) || null;
  };

  const handleReceiverChanged = (receiver?: counterPartyModel) => {
    setValue(`splitQuantities.${props.index}.counterPartyId`, receiver?.id);
    setValue(`splitQuantities.${props.index}.counterParty`, receiver);
  };

  const handleProductChanged = (product?: productModel) => {
    setValue(`splitQuantities.${props.index}.productId`, product?.id || '');
    setValue(`splitQuantities.${props.index}.product`, product);
  };

  const handleLoadingPortChanged = (port: UpdateDealPortInput | null) => {
    setValue(`splitQuantities.${props.index}.loadingPortId`, port === null ? undefined : port?.id);
    setValue(`splitQuantities.${props.index}.quantity`, port?.quantity);
  };

  const lblName = capitalizeFirstLetterEveryword(t(TXT_DEAL_NUMBER));
  const lblParentName = capitalizeFirstLetterEveryword(t(TXT_PARENT_NUMBER));

  return (
    <fieldset>
      <legend>{watchSplitQuantity.name || `${t(TXT_RECEIVER).toUpperCase()} #${props.index + 1}`}</legend>
      {!!watchSplitQuantity.canDelete && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            marginBottom: theme.spacing(1)
          }}
        >
          <IconButton
            aria-label={`delete-split-quantities-${props.index}`}
            onClick={() => props.handleRemove(props.index)}
            disabled={isDisabled}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Controller
            control={control}
            name={`splitQuantities.${props.index}.name`}
            render={({ fieldState }) => (
              <TextField
                {...register(`splitQuantities.${props.index}.name` as const)}
                label={lblName}
                variant="outlined"
                fullWidth
                error={!!fieldState.error}
                helperText={
                  !fieldState.error?.message
                    ? null
                    : capitalize(
                      t(fieldState.error.message!, {
                        example: tenant.dealNamePatternExample
                      })
                    )
                }
                disabled={isDisabled}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Controller
            name={`splitQuantities.${props.index}.counterPartyId`}
            control={control}
            render={() => (
              <CounterPartySelector
                counterParty={watchSplitQuantity.counterParty}
                onChanged={(cParty) => {
                  handleReceiverChanged(cParty);
                }}
                error={!!formErrors.splitQuantities?.[props.index]?.counterPartyId}
                label={t(TXT_RECEIVER)}
                counterPartyType="CLIENT"
                disabled={isDisabled}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <IncoTermSelector
            incoTerm={watchSplitQuantity.incoTerm}
            onSelectedChange={(term) => {
              setValue(`splitQuantities.${props.index}.incoTerm`, term === null ? '' : term);
            }}
            error={!!formErrors.splitQuantities?.[props.index]?.incoTerm}
            disabled={isDisabled || !!watchSplitQuantity.counterParty?.businessUnitId}
            helperText={!!watchSplitQuantity.counterParty?.businessUnitId ? t(TXT_INTERNAL_DEAL).toLowerCase() : ''}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Controller
            name={`splitQuantities.${props.index}.loadingPortId`}
            control={control}
            render={() => (
              <Autocomplete
                id={`splitQuantities.${props.index}.loadingPortId`}
                options={watchLoadingPorts || []}
                getOptionLabel={(option) => option.port?.name || ''}
                value={getSelectedPort(true)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, port) => {
                  handleLoadingPortChanged(port);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t(capitalizeFirstLetterEveryword(TXT_LOADING_PORT))}
                    error={!!formErrors.splitQuantities?.[props.index]?.loadingPortId}
                    disabled={isDisabled}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Controller
            name={`splitQuantities.${props.index}.unloadingPortId`}
            control={control}
            render={() => (
              <Autocomplete
                id={`splitQuantities.${props.index}.unloadingPortId`}
                options={watchUnloadingPorts || []}
                getOptionLabel={(option) => option.port?.name || ''}
                value={getSelectedPort(false)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(event, port) => {
                  setValue(`splitQuantities.${props.index}.unloadingPortId`, port === null ? undefined : port?.id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t(capitalizeFirstLetterEveryword(TXT_UNLOADING_PORT))}
                    error={!!formErrors.splitQuantities?.[props.index]?.unloadingPortId}
                    disabled={isDisabled}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <NumberTextField
            label={capitalizeFirstLetterEveryword(TXT_QUANTITY)}
            value={watchSplitQuantity.quantity || ''}
            onChange={(e) => setValue(`splitQuantities.${props.index}.quantity`, parseFloat(e.currentTarget.value))}
            error={!!formErrors.splitQuantities?.[props.index]?.quantity}
            variant="outlined"
            fullWidth
            disabled={isDisabled}
            InputProps={{
              endAdornment: <InputAdornment position="end">{watchMassUnit || ''}</InputAdornment>
            }}
            inputProps={{
              step: '0.001'
            }}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <ProductSelector
            product={watchSplitQuantity.product}
            onChanged={(prd) => {
              handleProductChanged(prd);
            }}
            error={!!formErrors.splitQuantities?.[props.index]?.productId}
            disabled={isDisabled}
          />
        </Grid>

        <Grid item xs={12} md={4} lg={4}>
          <Controller
            name={`splitQuantities.${props.index}.traderId`}
            control={control}
            render={() => (
              <TraderSelector
                trader={watchSplitQuantity.trader}
                onChanged={(trader) => {
                  setValue(`splitQuantities.${props.index}.traderId`, trader?.id);
                  setValue(`splitQuantities.${props.index}.trader`, trader);
                }}
                error={!!formErrors.splitQuantities?.[props.index]?.traderId}
                disabled={isDisabled}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={4} lg={4}>
          <Controller
            name={`splitQuantities.${props.index}.businessUnitId`}
            control={control}
            render={() => (
              <BusinessUnitSelector
                businessUnit={watchSplitQuantity.businessUnit}
                onChanged={(businessUnit) => {
                  setValue(`splitQuantities.${props.index}.businessUnitId`, businessUnit?.id);
                  setValue(`splitQuantities.${props.index}.businessUnit`, businessUnit);
                }}
                error={!!formErrors.splitQuantities?.[props.index]?.businessUnitId}
                disabled={isDisabled}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={4} lg={4}>
          <Controller
            name={`splitQuantities.${props.index}.trafficSpecialistId`}
            control={control}
            render={() => (
              <UserSelector
                user={watchSplitQuantity.trafficSpecialist}
                label={TXT_TRAFFIC_ANALYST}
                onChanged={(user) => {
                  setValue(`splitQuantities.${props.index}.trafficSpecialistId`, user?.id);
                  setValue(`splitQuantities.${props.index}.trafficSpecialist`, user);
                }}
                error={!!formErrors.splitQuantities?.[props.index]?.trafficSpecialistId}
                disabled={isDisabled}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          {watchVessel?.vesselType === 'LAKER' && (
            <TextField
              id={`splitQuantities.${props.index}.parentSplitQuantityName`}
              label={lblParentName}
              defaultValue={watchSplitQuantity.parentSplitQuantityName || ''}
              variant="outlined"
              fullWidth
              onBlur={(e) => setValue(`splitQuantities.${props.index}.parentSplitQuantityName`, e.currentTarget.value)}
              error={!!formErrors.splitQuantities?.[props.index]?.parentSplitQuantityName}
              disabled={isDisabled}
            />
          )}
        </Grid>
      </Grid>
    </fieldset>
  );
};
